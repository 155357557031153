import Head from "next/head";
import { useRouter } from "next/router";

import { decode } from "html-entities";

const seoData = {
  "/": {
    title: "힐링하트: 내 주변 국가공인 안마원 마사지, 안전한 힐링의 시작",
    description:
      "힐링하트에서 내 주변 국가공인 안마원의 전문 마사지 서비스를 빠르고 쉽게 찾아보세요.",
  },
  "/company": {
    title: "회사소개 | 힐링하트",
    description:
      "힐링하트는 건전한 샵을 통해 누구나 마음 편한 힐링문화를 만들어 나갑니다.",
  },
  "/terms/usage": {
    title: "이용약관 | 힐링하트",
    description: "이용약관 안내",
  },
  "/terms/location": {
    title: "위치기반서비스 이용약관 | 힐링하트",
    description: "위치기반 서비스 이용약관 안내",
  },
  "/terms/privacy": {
    title: "개인정보처리방침 | 힐링하트",
    description: "개인정보처리방침 안내",
  },
  "/terms/personal": {
    title: "개인정보 수집 및 이용 | 회원가입 | 힐링하트",
    description: "힐링하트 개인정보 수집 및 이용",
  },
  "/menu": {
    title: "My 힐링하트 | 힐링하트",
    description: "여기를 눌러 링크를 확인하세요.",
  },
  "/member/login": {
    title: "로그인 | 힐링하트",
    description: "힐링하트 로그인",
  },
  "/member/regist": {
    title: "회원가입 | 힐링하트",
    description: "힐링하트 회원가입",
  },
  "/member/profile": {
    title: "내 정보 수정 | 힐링하트",
    description: "힐링하트 내 정보 수정",
  },
  "/member/withdraw": {
    title: "회원탈퇴 | 내 정보 수정 | 힐링하트",
    description: "힐링하트 회원탈퇴",
  },
  "/search": {
    title: "검색 | 힐링하트",
    description: "국가공인 안마원 마사지 검색",
  },
  "/review/[author]": {
    title: "사용자 후기 모아보기 | 힐링하트",
    description: "사용자 후기 모음",
  },
  "/zzimlist": {
    title: "즐겨찾기 | 힐링하트",
    description: "즐겨찾기. 로그인이 필요한 페이지입니다.",
  },
  "/review": {
    title: "후기관리 | 힐링하트",
    description: "후기관리. 로그인이 필요한 페이지입니다.",
  },
  "/inquiry/form": {
    title: "1:1문의 | 힐링하트",
    description: "문의하기. 로그인이 필요한 페이지입니다.",
  },
  "/inquiry/list": {
    title: "문의내역 | 힐링하트",
    description: "문의내역. 로그인이 필요한 페이지입니다.",
  },
  "/inquiry/[slug]": {
    title: "문의내역 상세 | 힐링하트",
    description: "문의내역 상세. 로그인이 필요한 페이지입니다. ",
  },
  "/partnership": {
    title: "제휴문의 | 힐링하트",
    description:
      "힐링하트와 제휴하여 원하는 지역에 광고를 진행하세요. 추가 요금으로 여러 지역에 동시에 광고하는 것도 가능합니다, 더 넓은 고객층을 손쉽게 공략해 보세요.",
  },
  "/partnership/calculator": {
    title: "계산기 | 제휴문의 | 힐링하트",
    description: "힐링하트 제휴신청하기. 로그인이 필요한 페이지입니다.",
  },
  "/partnership/form": {
    title: "제휴신청하기 | 힐링하트",
    description: "힐링하트 제휴신청하기. 로그인이 필요한 페이지입니다.",
  },
  "/partnership/[slug]": {
    title: "입금안내 | 제휴신청하기 | 힐링하트",
    description: "힐링하트 입금안내. 로그인이 필요한 페이지입니다.",
  },
  "/notice": {
    title: "공지사항 | 힐링하트",
    description: "힐링하트 서비스 및 운영 관련 공지사항입니다.",
  },
  "/event": {
    title: "이벤트 | 힐링하트",
    description: "힐링하트 이벤트 정보를 확인하세요.",
  },
  "/blog": {
    title: "블로그 | 힐링하트",
    description: "힐링하트 블로그입니다.",
  },
  "/location": {
    title: "위치를 설정해 주세요 | 힐링하트",
    description:
      "원하는 지역을 설정해 가까운 국가공인 안마원 마사지 서비스를 찾아보세요",
  },
  "/404": {
    title: "오마이갓 | 힐링하트",
    description: "여기를 눌러 링크를 확인하세요.",
  },
};

export default function getHeader(passedData = {}) {
  const router = useRouter();
  const data = {
    title: "힐링하트 | 1등 힐링정보 플랫폼",
    description:
      "힐링하트는 국가공인 안마원 마사지 서비스 정보를 제공합니다. 힐링하트에서 동네 주민이 남긴 진짜 후기를 확인해보세요!",
    keywords:
      "힐링하트, 마사지, 타이마사지, 중국마사지, 프랜차이즈, 왁싱, 스포츠마사지, 아로마, 경락·딥티슈, 1인샵, 스웨디시, 수면가능, 스파·사우나, 피부관리, 24시간, 한국인 마사지, 단체·커플, 홈케어, 체형관리, 여성전용",
    image: process.env.DOMAIN + require("public/images/og/og-default.jpg"),
  };

  const resData = {
    ...data,
    ...(seoData?.[router.pathname] ? seoData[router.pathname] : {}),
    ...passedData,
  };
  const excPaths = [
    "/shop/[slug]",
    "/sitemap/area/[category]",
    "/sitemap/metro/[category]",
  ];
  const canonical = excPaths.includes(router.pathname)
    ? `${process.env.DOMAIN}${router.asPath.split("?")[0]}`
    : `${process.env.DOMAIN}${router.asPath
        .split("#")[0]
        .replace("amp", "")
        .replace(/^\/|\/+$/g, "/")}`.replace(/\/+$/g, "/");
  const decodeHTMLEntities = (value) => decode(value);

  return (
    <Head>
      <title>{decodeHTMLEntities(resData.title)}</title>
      <meta
        name="description"
        content={decodeHTMLEntities(resData.description)}
      />
      {/* <meta name="keywords" content={ decodeHTMLEntities(resData.keywords) } /> */}

      <meta property="og:site_name" content="힐링하트" />
      <meta property="og:url" content={process.env.DOMAIN + router.asPath} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={decodeHTMLEntities(resData.title)} />
      <meta
        property="og:description"
        content={decodeHTMLEntities(resData.description)}
      />
      <meta property="og:image" content={resData.image} />

      <meta name="twitter:title" content={decodeHTMLEntities(resData.title)} />
      <meta
        name="twitter:description"
        content={decodeHTMLEntities(resData.description)}
      />
      <meta name="twitter:image" content={resData.image} />

      <link rel="canonical" href={canonical} />
    </Head>
  );
}
